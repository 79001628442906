.sub-menu {
    list-style: none;
    padding: 0 0 0 0;
}

.arrow {
    margin-left: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    transition: transform 0.3s ease, color 0.3s ease;
    color: grey; /* Default color */
}

.arrow.flip {
    transform: rotate(360deg);
    color: #a72c32; /* Color when clicked */
}
.sub-menu li {
    text-align: center;
    padding: 10px 0;

}
.menu {
    color: grey; /* Default color */
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    transition: color 0.3s ease;
}

.menu a {
    color: grey; /* Default color */

}

.menu.active {
    color: #a72c32; /* Color when clicked */
}

/* Header styles */
.navbar {
    display: none;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    color: white;
    position: relative; /* Add relative positioning */
    z-index: 9999; /* Add z-index to allow spill over other elements */
}

.logo {
    font-size: 20px;
}

/* Desktop navigation links */
.nav-links {
    display: none;
    justify-content: flex-end;
    list-style-type: none;
    width: 75%;
}

/* Common navigation link styles */
.home,
.skill,
.contact,
.about {
    text-decoration: none;
    color: white;
    font-size: 15px;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
}

/* Mobile menu icon */
.mobile-menu-icon {
    display: none;
}

/* Media query for mobile layout */
@media screen and (max-width: 1074px) {
    .navbar {
        position: absolute; /* Ensure the parent container has relative positioning */
        display: inline;
    }


    .nav-links-mobile {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.94);
        list-style: none;
        text-align: center;
        padding: 20% 0;
        opacity: 0; /* Start with opacity 0 */
        visibility: hidden; /* Start with visibility hidden */
        transition: opacity 0.5s ease, visibility 0.5s ease; /* Add transition for opacity and visibility */
    }
    /* Adjusted styles for mobile navigation links */
    .club-bravo,
    .rewards,
    .scan-ticket,
    .register,
    .contact-us,
    .faq {
        color: rgb(0, 0, 0);
        text-align: center;
        padding: 10px 0;
        display: block;
    }

    .club-bravo:hover,
    .rewards:hover,
    .scan-ticket:hover,
    .register:hover,
    .contact-us:hover,
    .faq:hover {
        color: rgb(196, 131, 131);
        font-size: 25px; /* Increase font size on hover */
    }

    /* Mobile menu icon styles */
    .mobile-menu-icon {
        display: block;
        position: absolute;
        font-size: 30px;
        background: transparent;
        border: none;
        outline: none;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .nav-links-mobile.show {
        opacity: 1; /* Fade in to opacity 1 */
        visibility: visible; /* Make it visible */
    }
}
