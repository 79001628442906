.img-slider-dot-btn {
    all: unset;
    display: block;
    cursor: pointer;
    font-size: 2rem;
    color: grey;
    transition: scale 100ms ease-in-out;
}

.img-slider-dot-btn:hover {
    scale: 1.2;
}
.img-slider-dot-btn.active {
    color: white;
}
.img-slider-left-btn {
    left: 0;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
    border-style: none;
    background-color: transparent;
}

.img-slider-right-btn {
    right: 0;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 1rem;
    cursor: pointer;
    transition: background-color 100ms ease-in-out;
    border-style: none;
    background-color: transparent;
}

.img-slider-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    transition: translate 500ms ease-in-out;
    flex-shrink: 0;
    flex-grow: 0;
}
